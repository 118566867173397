<template>
  <div class="form-section">
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible" custom-class="" title="" top="3vh" width="1228px">
      <report-print-form orientation="landscape" v-if="dialogVisible" :title="reportTitle" @close="dialogVisible = false" :subTitle="dueDateLabel" :reportColumns="reportColumns" :reportData="reportData" :reportCellCustomClass="reportCellCustomClass"></report-print-form>
    </el-dialog>

    <el-row>
      <el-col style="width: fit-content">
        <div class="title-2">WSIB</div>
      </el-col>
      <el-col :class="$langConfig.cssClass" style="width: 210px;margin-left: 10px">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :editMode="editMode"
            :field_span="11"
            :label_span="13"
            :options="[ ...dropdownTaxReportingPeriods,  {'label': 'All', value: '-all-'}]"
            :translateLabel='false'
            :translateLabels="false"
            @amUpdated="(p, val) =>  { taxReportingPeriod = val}"
            field_mark="required"
            label="Reporting Type"
            name="reporting_type"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" style="width: 160px;margin-left: 10px;">
        <dg-select-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :editMode="editMode"
            :field_span="10"
            :label_span="13"
            :options="dropdown12Month"
            :translateLabel='false'
            :translateLabels="false"
            field_mark="required"
            label="Period End"
            name="period_due"
            placeholder=""
            rules="required"></dg-select-field>
      </el-col>

      <el-col :class="$langConfig.cssClass" style="width: 80px; margin-left: 10px;">
        <dg-single-checkbox-field
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :field_span="7"
            :labelThenField="true"
            :label_span="15"
            :translateLabel='false'
            @amUpdated=" (p, v) => { withHighPriority = v;(v)? reportTitle = 'WSIB LIST' : reportTitle = 'WSIB LIST'}"
            field_mark=""
            label="Priority"
            name="just_high_priority"
            rules=""></dg-single-checkbox-field>
      </el-col>

      <el-button @click="report" size="small" style="position: absolute;right: 10px;" type="primary">Report</el-button>
    </el-row>
  </div>
</template>

<script>
import TaxFilingTypeJson from "@/json/dropdown/TaxFilingType.json";
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
import jsonAccountReportingPeriods from "@/json/dropdown/AccountingReportingPeriods.json";
import reportingMethodJson from "@/json/dropdown/ReportingMethod.json";

export default {
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
  props: {
    province_of_operation_id: {
      required: true
    }
  },
  components: {
    'report-print-form': () => import('@/modules/Accounting/Report/report-print-form/View')
  },
  methods: {
    reportCellCustomClass(cell, data) {
      if (cell.prop == 'business_name') {
        if (data.business_priority_id == '2' && this.withHighPriority) {
          return 'bg-warning'
        }
      }

      return '';
    },
    fillReportCell(cell, data) {
      if (cell.from == 'empty') return ''
      if (cell.from == 'api') return data[cell.prop]
      if (cell.from == 'local') return this[cell.prop]
    },
    async report() {
      this.requestErrorMessage = null
      let isValid = await this.validateFields().then(result => result).finally(() => {
        this.requestOn = false
      })
      if (!isValid) return

      let currentYear = new Date().getFullYear();
      let lastDayOfMonth = new Date(currentYear, this.dataModel.period_due + 2, 0).getDate();
      let nextMonthName = new Date(currentYear, this.dataModel.period_due + 1, 1).toLocaleString('default', {month: 'short'});
      this.dueDateLabel = "Due Date: " + nextMonthName + " " + lastDayOfMonth + ", " + currentYear
      this.accountingTypeLabel = this.getAccountingTypeFromId(this.dataModel.reporting_type)


      $store.dispatch('Accounting-Report-api/taxReportTaxWsib', this.dataModel).then(r => {
        this.reportData = r
        this.openDataInDialogToPrint()
      })
    },
    openDataInDialogToPrint() {
      this.dialogVisible = true
    },
    getAccountingTypeFromId(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
    getGstHstTvqReportingType(id) {
      let o = this.dropdownAccountingOptions;
      let l = o.length
      for (let i = 0; i < l; i = i + 1) if (o[i].value == id) return o[i].label
      return ''
    },
  },
  beforeMount() {
    this.dataModel['province_of_operation_id'] = this.province_of_operation_id
    this.reportColumns = [
      ...$services['Accounting-Report'].getReportColumns(['business_name', 'contact_name', 'full_work_phone', 'tax_wsib_reporting_period_type_to_print', 'ontario_flag', 'cheque_number', 'phone_icon'])
    ]
  },
  data() {
    return {
      dropdownReportingMethod: reportingMethodJson,
      withHighPriority: false,
      sortByOptions: [
        {
          label: 'Province',
          value: 'business_province_of_operation_id',
        },
        {
          label: 'Business Name',
          value: 'business_name',
        }
      ],
      sortOrderOptions: [
        {
          label: 'ASC',
          value: 'asc',
        },
        {
          label: 'DESC',
          value: 'desc',
        }
      ],
      accountingTypeLabel: '',
      dropdownAccountingOptions: jsonAccountReportingPeriods,
      dueDateLabel: '',
      printPageWidth: 3508,
      printPageMargin: 10,
      reportTitle: "WSIB LIST",
      reportData: [],
      reportColumns: [],
      dialogVisible: false,
      control: this,
      TaxFilingTypeDropdown: TaxFilingTypeJson,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      dropdown12Month: json12month,
      taxReportingType: null,
    }
  }
}
</script>

<style scoped>
.table-for-printing tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #c8c8c8;
}

.table-for-printing tbody:not(:nth-child(2)) tr:first-child {
  height: 50px;
  vertical-align: bottom
}

.table-for-printing tr:nth-child(odd) td div {
  border: 2px solid #000 !important;
  border-radius: 8px;
}

.table-for-printing-with-high-priority tr:nth-child(even) td div {
  -webkit-print-color-adjust: exact;
  background-color: #f1d03b;
  color: #000;
}

.table-for-printing-with-high-priority tr:nth-child(odd) td div {
  border: 2px solid #f1d03b !important;
  border-radius: 8px;
}
</style>
